import { onMounted, ref, watchEffect } from 'vue'

import type { ArticlePageContent } from '@backmarket/http-api/src/api-specs-content/models/article-page-content'
import type {
  BasePageContent,
  Context,
} from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { usePageParamsFromRoute } from './usePageParamsFromRoute'
import { useValidateBlocks } from './useValidateBlocks'

type NotNull<T> = Exclude<T, null | undefined>

// checks if the prop exists and is not null/undefined in an object
// if it's the case, the object is narrowed to the type with the prop required
const hasNotNullProp = <T extends object, Key extends keyof T>(
  obj: T,
  prop: Key,
): obj is T & Required<Record<Key, NotNull<T[Key]>>> => {
  return !!obj && typeof obj === 'object' && prop in obj && !!obj[prop]
}

export function useFetchPageContext({
  showCmsContext,
  content,
}: {
  showCmsContext: boolean
  content: ArticlePageContent | BasePageContent | null
}) {
  const userStore = useUserStore()
  const pathParams = usePageParamsFromRoute()
  const $httpFetch = useHttpFetch()
  const logger = useLogger()

  const context = ref<Context>()

  onMounted(() => {
    watchEffect(async () => {
      if (!showCmsContext) return

      if (!userStore.user.isStaff) return

      try {
        context.value = hasNotNullProp(pathParams, 'pageCategory')
          ? await $httpFetch(
              '/content/admin/page/:pageType/:pageName/:pageCategory',
              { pathParams },
            )
          : await $httpFetch('/content/admin/page/:pageType/:pageName', {
              pathParams,
            })
      } catch (error) {
        logger.error('[CMS CONTEXT FETCH FAILED]', {
          error: error as Error,
          owners: ['bot-squad-content-front'],
        })
      }

      if (!!content && context.value) {
        context.value.errors = [
          ...(context.value.errors || []),
          ...useValidateBlocks(content.blocks),
        ]
      }
    })
  })

  return context
}
