import type { PageCMSError } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import {
  BlockPropsMapSchema,
  type _Block,
} from '@backmarket/http-api/src/api-specs-content/models/block'
import { get } from '@backmarket/utils/object/get'
import { type ZodError, type ZodIssue } from 'zod'

function validateBlock(block: _Block) {
  return BlockPropsMapSchema[block.type].safeParse(block.props)
}

function createIssueMessage(issue: ZodIssue) {
  return `${issue.message}: ${issue.path[issue.path.length - 1]}`
}

function getIssueEntryId({ block, issue }: { block: _Block; issue: ZodIssue }) {
  if (issue.path.length === 1) return null

  const pathToClosestParent = issue.path.slice(0, issue.path.length - 1)

  return get(block.props, pathToClosestParent)?.id || null
}

function formatErrors({ block, error }: { block: _Block; error: ZodError }) {
  return error.issues.reduce((acc, issue) => {
    // Early returns to only display missing field errors
    if (issue.code !== 'invalid_type') return acc
    if (!issue.message.match(/required/i)) return acc

    return [
      ...acc,
      {
        blockId: block.id,
        entryId: getIssueEntryId({ block, issue }),
        blockName: block.type,
        message: createIssueMessage(issue),
      },
    ]
  }, [] as PageCMSError[])
}

export function useValidateBlocks(blocks: _Block[]): PageCMSError[] {
  return blocks.reduce((acc, block) => {
    const blockErrors = validateBlock(block)

    if (blockErrors?.success) return acc

    const formattedErrors = formatErrors({
      block,
      error: blockErrors.error,
    })

    return [...acc, ...formattedErrors]
  }, [] as PageCMSError[])
}
